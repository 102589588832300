import React from 'react'

function Venue() {
  return (
    <div>
      <div class="venue"><div><p>This conference took place on September 21, 2018.<br/><br/>
        University of Reading<br />
        Madjieski Lecture Theatre<br />
        Room RGL04<br />
        Agriculture Building</p>
        </div>
        {/* <div><p><a href="http://www.reading.ac.uk/about/visit-us.aspx" target="_blank" rel="noopener">Travel Information</a><br />
          <a href="/conference-venue.jpg" target="_blank" rel="noopener">Download Map</a></p>
        </div> */}
      </div>
    </div>
  )
}

export default Venue
