import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Timetable from "../components/conference/timetable";
import Playlist from "../components/conference/playlist";
import Venue from "../components/conference/venue";
import Speakers from "../components/conference/speakers";

class ConferencePage extends React.Component {
  render() {
    return (
      <Layout color="black" background="#89C190" classNames="page page--conference">
        <SEO title="Conference" pathname={this.props.location.pathname} description="This conference is the first iteration of EuroNoize, a collaborative project held between the University of Reading, Kunsthall Oslo and A.R.E. Prague and funded by the European Commission."/>
        <div>
          <div className="container p-4">
            <h2 className="text-center">Conference</h2>
            <h3 className="font-outline text-2xl text-center w-full md:w-2/3 mx-auto">Art Bands, DiY Music and Cultural Identity in an Age of Transnational Mobility</h3>
            <p className="font-sans text-center mb-8">21.09.2018</p>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 md:pr-8 md:text-xs">
                <p>This conference was the first iteration of EuroNoize, a collaborative project held between the University of Reading, Kunsthall Oslo and A.R.E. Prague and funded by the European Commission. The project aims to explore the history and existing practices of the DiY music scene in Europe and beyond, to evaluate this particular form of artistic production alongside other contemporary aesthetic modes, to consider its historical and current relationship to art education (the art band emerging from art school) and to consider the social, economic and cultural structures that shape it in the present. In this context, we will be exploring the relationship between a global (predominantly Anglo-Saxon) culture industry and localised and independent nodes of production. This one-day conference serves as a starting point in generating a theoretical discussion around an artistic and musical genre that is rarely given enough attention in art criticism.</p>
              </div>
              <div className="w-full md:w-1/2 font-sans py-8 md:pl-8 md:py-0">
                <Speakers />
              </div>
            </div>

            <div className="my-16">
              <h2 className="text-center">Timetable</h2>
              <Timetable />
            </div>

            <div className="my-16">
              <h2 className="text-center">Playlist</h2>
              <Playlist />
            </div>

            <div className="my-16">
              <h2 className="text-center">Venue</h2>
              <Venue />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ConferencePage
