import React from 'react'

function Timetable() {
  return (
    <div class="timetable"><ul><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">9:30</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"></div><div class="timetable__speaker"></div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Coffee and registration</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">10:00</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>Introduction</h4></div><div class="timetable__speaker">Pil and Galia Kollectiv</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Introducing the central themes for EuroNoize, project co-ordinators Pil and Galia Kollectiv will address questions around migration, translation and appropriation. DiY music is caught between the ethos of art and the market of the music industry, between Anglo-Saxon cultural markers and local&nbsp;European identities and between a sense of spectacle and a poverty of means of production. It is these tensions and contradictions&nbsp;that define it as a unique cultural activity that, because of these structural fissures at its base, rarely receives due recognition and institutional support.</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">10:30</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>Glam Rock, Fashion, and DIY</h4></div><div class="timetable__speaker">Philip Auslander</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>British Glam Rock of the early 1970s cannot properly be said to have been DIY music. It was produced as commercial product by industry professionals, albeit professionals mostly at the early stages of their careers. The outlandish, gender-bending appearances glam rockers assumed through clothing and make-up might appear to reflect a DIY aesthetic, but were in reality largely the work of professional designers and stylists. In this respect, Glam both carries on a tradition well established in rock since the 1950s and anticipates the tight connection between music and fashion in Punk. Glam’s openness to the performers’ newly created, highly mutable on-stage identities, their musical personae, and the relative absence of an established norm for the glam rock persona were unprecedented. It is here that the DIY impulse could find expression in Glam.</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">11:15</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>The Art School Dance Goes On Forever</h4></div><div class="timetable__speaker">Bryan Biggs</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>A contemporary of Liverpool art school band, Deaf School, in the 1970s, I have charted the interaction between art and music, particularly pop, over several decades, through exhibitions and publications. This presentation will draw on some of these, including live art commissions <strong>Live from the Vinyl Junkyard and Mixing It</strong> (1996/97), which featured Jeremy Deller’s <strong>Acid Brass</strong>; a contemporary recreation of the <strong>Sgt Pepper</strong> LP cover tableau (1997), interrogating cultural icons, appropriation and plagiarism; a documentary exhibition (2013) to accompany Paul Du Noyer’s book, <strong>Deaf School: The Non-Stop Pop Art Punk Rock Party</strong>; and a <strong>Captain Beefheart Weekend</strong> (2017) that included a symposium considering him as a ‘total artist’ who blurred distinctions between his music, art, poetry and performance. Using these and other examples, the talk will argue for the continuing vitality of a range of ‘popular’ musics to inform, enliven and disrupt visual arts practice.</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">11:45</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>Nice Style: Self-Fashioning in Post-Punk Glasgow Art Bands</h4></div><div class="timetable__speaker">Sarah Lowndes</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>In this paper, Sarah Lowndes will discuss the inter-related art and music scenes of Glasgow in the post-punk era, paying particular attention to the lyrics, personal style and artwork of bands including Orange Juice, Strawberry Switchblade, The Pastels, Franz Ferdinand, Life Without Buildings, Correcto and Muscles of Joy.&nbsp; The paper will consider the art band as a genre of contemporary art, in relation to other contemporary post-studio art disciplines so prevalent in the Glasgow art scene, such as performance, sound art and art writing.</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">12:15</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"></div><div class="timetable__speaker"></div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Plenary</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">13:00</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"></div><div class="timetable__speaker"></div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Lunch</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">14:00</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>The Academy Imperils</h4></div><div class="timetable__speaker">Chris Bohn</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p><strong>Fuck off Foucault…</strong> In “I’d rather choose The curb”: Topographical Writing In Recent German Punk, a writer named Dennis Borghardt quotes some “significant and surprising lines” from Michel Foucault’s “Of Other Spaces”. Like, is this pensée from a late French thinker really the best place to start an essay about German punk?</p>
      <p>Dennis Borghardt’s essay is also the opening chapter of Beyond No Future: Cultures Of German Punk (Bloomsbury Academic, 2016). Nowhere does this book about German punk account for Abwärts, possibly the greatest, most sardonic and savagely satirical of all West German punk groups. This paper celebrates the music of an overlooked group, formed in Hamburg in 1979, and treats them as exemplars of the West German punk explosion that seeded modern Germany’s vibrant popular culture without any help from the academy or the state’s cultural bureaucrats.</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">14:30</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>Pretty in Pink or The Black is the New Pink: post-colonial identities and DIY music as form of political and social resistance</h4></div><div class="timetable__speaker">Paula Guerra</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>In the last decade, the post-subcultural framework has opened a fundamental gap in the study of youth cultures, namely underground music scenes and DIY cultures. This window of opportunities – also known as cultural turn – has allowed three major advances in social theory: the re-examination of classical subcultural analysis through the introduction of critical variables of gender, ethnicity, social networks and subcultural aging, among others; on the other hand, it has allowed the unveiling and relevance of hybridisms and transglobal crosses between genres and musical subgenres (rrriot punk, funk, tecnobrega, sertanejo, reggaeton, electro guerrilla), as well as the pertinence of these artistic and musical creations as forms of alternative economy and insertion in the market via the underground; and the emergence of these artistic and musical productions in the sphere of the DIY ethos as a form of political and social resistance. Thus, considering the collectives of musical actors in the cities of Rio de Janeiro, Fortaleza, Recife, Teresina, Juiz de Fora and Porto Alegre interviewed between 2017 and 2018, we intend to demonstrate the empirical relevance and urgency of these three dimensions of approach and their relevance to the renewal of social theory about popular music and the contemporary construction of social identities in a local, transglobal and virtual sphere.</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">15:00</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>Decolonising Punk: What it means to redefine punk within racial identity</h4></div><div class="timetable__speaker">Stephanie Phillips</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>In 2016, after mulling over the idea for years Phillips’ posted on her personal social media page, asking if anyone wanted to see a festival for punks of colour to exist. The response Phillips’ received was immediate and it was overwhelming. With a few more punks on board she created Decolonise Fest, the first music UK festival created by and for people of colour. This talk will discuss Decolonise Fest and the history of people of colour in punk, ending with a Q&amp;A. It will cover the following key areas: Why Phillips started Decolonise Fest; How and why punk history has become whitewashed; How to bring the history of punks of colour to centre stage and the impact it could have; Why Phillips wanted to make space for people of colour in the punk scene; Feedback the group received from other punks; Why in today’s political landscape punk is made for people of colour and; Does the future of punk lie with people of colour?</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">15:30</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"></div><div class="timetable__speaker"></div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Plenary</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">16:00</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"></div><div class="timetable__speaker"></div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Coffee break</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">16:30</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>DIY – then, now, tomorrow</h4></div><div class="timetable__speaker">Simon Reynolds</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>This paper looks at the history of the concept and practice of do-it-yourself culture, starting with the self-published radical periodicals of the Sixties counter-culture and science fiction zines, through the postpunk’s era explosion of self-released music, to the home-studio autonomy of rave and electronic music in the Nineties, and then onto the dizzying profusion of micro-genres and micro-scenes in today’s internet-mediated music culture. I will also examine and unpick the ideology of DIY, tracing its evolution from the Situationist critique of the Spectacle, through punk’s ambivalent relationship with mass media, to the present moment where ideas of “alternative” and “underground” continue to exert a seductive romance and urgency but have also been co-opted and weaponized by anti-progressive forces. &nbsp;Finally, the paper will look at how social media and internet platforms like Blogger and YouTube have changed DIY, at once opening up new avenues for autonomous expression and community-building, yet also harnessing the creative impulses &nbsp;of millions for corporate ends. Is it possible to speak now of the Spectacle 2.0? &nbsp;What is the future hold for the ideal and actuality of do-it-yourself culture?</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">17:00</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"><h4>Whip In My Valise: British Punk and the Marquis de Sade, c. 1975–85</h4></div><div class="timetable__speaker">Matt Worley</div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>British punk emerged in tandem with the formation of Sex Pistols, a band framed by a style and an aesthetic constructed, in part, by Malcolm McLaren and Vivienne Westwood via their London shop SEX (1974–76). The shop displayed fetishwear and accoutrements designed to fuse youth and sexual subcultures, deploying sex as a cultural weapon to provoke and confront. This article examines the Sadean influences that found expression through punk, suggesting that the Marquis de Sade had a seminal if diffused impact on the punk-informed cultures that evolved through the 1970s into the 1980s. Though often indirect – and bound to broader interpretations of sexual behaviour – the actions, aesthetics and ideas associated with Sade seemingly tallied with the ‘mood’ of a country caught in a period of socio-economic and political change.</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">17:30</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"></div><div class="timetable__speaker"></div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Plenary</p>
    </div></div></li><li class="timetable__item"><div class="timetable__cell timetable__cell--time"><div class="timetable__time">18:00</div></div><div class="timetable__cell timetable__cell--info"><div class="timetable__title"></div><div class="timetable__speaker"></div></div><div class="timetable__cell timetable__cell--desc"><div class="timetable__description"><p>Finish</p>
    </div></div></li></ul></div>
  )
}

export default Timetable
